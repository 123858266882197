import {useMediaQuery} from "react-responsive";
import Container from "./container";
import HomeStyle from "../less/home.module.less";
import button1Unselected from "../images/button.svg";
import button1Selected from "../images/button-selected.svg";
import {Button} from "antd";
import button2Selected from "../images/button-2-selected.svg";
import button2Unselected from "../images/button-2.svg";
import React from "react";
import useIntl from "../helpers/useIntl";

const HomeOverview = ({budget, selectedCategory, setSelectedCategory}) => {
  const intl = useIntl()

  const categories = budget.map((category, index) => {
    return {
      ...category,
      selected: index === selectedCategory,
    }
  })

  const isTabletOrBigger = useMediaQuery({
    query: "(min-device-width: 768px)",
  })

  return (
    <Container size={"small"} noPadding={true}>
      <div className={HomeStyle.buttonWrapper}>
        {isTabletOrBigger &&
        <>
          <div className={HomeStyle.button}
               role='button'
               onClick={() => setSelectedCategory(0)}
               style={{
                 left: 0,
                 cursor: "pointer",
                 backgroundRepeat: 'no-repeat',
                 paddingRight: "50px",
                 color: categories[0].selected ? "white" : "var(--color-primary-light)",
                 backgroundImage: categories[1].selected ? `url('${button1Unselected}')` : `url('${button1Selected}')`,
               }}>
            <div className={HomeStyle.buttonText}>
            <span
              className={HomeStyle.buttonTitle}>{intl.formatMessage({id: 'home_to'})}</span>
              <hr/>
              <span className={HomeStyle.amount}>€ 10 miljard</span>
              {
                categories[1].selected &&
                <Button
                  type={"primary"}>{intl.formatMessage({id: 'home_view'})}</Button>
              }
            </div>
          </div>
          <div className={HomeStyle.button}
               role='button'
               onClick={() => setSelectedCategory(1)}
               style={{
                 right: 0,
                 cursor: "pointer",
                 backgroundRepeat: 'no-repeat',
                 color: categories[1].selected ? "white" : "var(--color-primary-light)",
                 paddingLeft: "50px",
                 backgroundImage: categories[1].selected ? `url('${button2Selected}')` : `url('${button2Unselected}')`,
               }}>
            <div className={HomeStyle.buttonText}>
          <span
            className={HomeStyle.buttonTitle}>{intl.formatMessage({id: 'home_from'})}</span>
              <hr/>
              <span className={HomeStyle.amount}>€ 10 miljard</span>
              {
                categories[0].selected &&
                <Button
                  type={"primary"}>{intl.formatMessage({id: 'home_view'})}</Button>
              }
            </div>
          </div>
        </>
        }

        {!isTabletOrBigger &&
        <>
          <div className={HomeStyle.button}
               role='button'
               style={{
                 left: 0,
                 paddingRight: "50px",
                 color: categories[0].selected ? "white" : "var(--color-primary-light)",
                 backgroundRepeat: 'no-repeat',
                 backgroundImage: categories[1].selected ? `url('${button1Unselected}')` : `url('${button1Selected}')`,
               }}>
            <div className={HomeStyle.buttonText}>
            <span
              className={HomeStyle.buttonTitle}>Waar gaat het geld naartoe?</span>
              <hr/>
              <span className={HomeStyle.amount}>€ 10 miljard</span>
              <Button type={"primary"}>Ontdek</Button>
            </div>
          </div>
          <div className={HomeStyle.button}
               role='button'
               style={{
                 right: 0,
                 color: categories[1].selected ? "white" : "var(--color-primary-light)",
                 paddingLeft: "50px",
                 backgroundRepeat: 'no-repeat',
                 backgroundImage: categories[1].selected ? `url('${button2Selected}')` : `url('${button2Unselected}')`,
               }}>
            <div className={HomeStyle.buttonText}>
          <span
            className={HomeStyle.buttonTitle}>Waar komt het geld vandaan?</span>
              <hr/>
              <span className={HomeStyle.amount}>€ 10 miljard</span>
              <Button type={"primary"}>Ontdek</Button>
            </div>
          </div>
        </>
        }
      </div>
    </Container>
  )
}

export default HomeOverview
