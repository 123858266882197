import React from "react"
import * as d3 from "d3"

import { lerp, lerpCubic } from "../helpers/animation"
import { useEffect, useRef, useState } from "react"

const PI = Math.PI
const HALF_PI = Math.PI / 2
const TWO_PI = Math.PI * 2

const gSettings = {
  // totalAngleDegrees: 90,
  desiredAngleDegrees: 100,
  zoomedRadius: 220,
  zoomedThickness: 30,
  zoomInfluence: 2.5,
  selectedSegmentIndex: 0,
  duration: 1000,
  tx: 0,
  zoom: true,
  zoomFactor: 4.0,
}

function clamp(v, min, max) {
  return v < min ? min : v > max ? max : v
}

function toRadians(deg) {
  return deg * (Math.PI / 180)
}

function measureContext() {
  let _minX = Infinity
  let _minY = Infinity
  let _maxX = -Infinity
  let _maxY = -Infinity

  function moveTo(x, y) {
    _minX = Math.min(_minX, x)
    _minY = Math.min(_minY, y)
    _maxX = Math.max(_minX, x)
    _maxY = Math.max(_maxY, y)
  }

  function lineTo(x, y) {
    _minX = Math.min(_minX, x)
    _minY = Math.min(_minY, y)
    _maxX = Math.max(_minX, x)
    _maxY = Math.max(_maxY, y)
  }

  function getQuadrant(_angle) {
    const angle = _angle % TWO_PI
    if (angle > 0.0 && angle < HALF_PI) return 0
    if (angle >= HALF_PI && angle < PI) return 1
    if (angle >= PI && angle < PI + HALF_PI) return 2
    return 3
  }

  function arc(tx, ty, r, a0, a1, ccw) {
    if (ccw) {
      ;[a0, a1] = [a1, a0]
    }
    const a0Quad = getQuadrant(a0)
    const a1Quad = getQuadrant(a1)

    const ix = Math.cos(a0) * r
    const iy = Math.sin(a0) * r
    const ex = Math.cos(a1) * r
    const ey = Math.sin(a1) * r

    const minX = Math.min(ix, ex)
    const minY = Math.min(iy, ey)
    const maxX = Math.max(ix, ex)
    const maxY = Math.max(iy, ey)

    // const r = radius;
    const xMax = [
      [maxX, r, r, r],
      [maxX, maxX, r, r],
      [maxX, maxX, maxX, r],
      [maxX, maxX, maxX, maxX],
    ]
    const yMax = [
      [maxY, maxY, maxY, maxY],
      [r, maxY, r, r],
      [r, maxY, maxY, r],
      [r, maxY, maxY, maxY],
    ]
    const xMin = [
      [minX, -r, minX, minX],
      [minX, minX, minX, minX],
      [-r, -r, minX, -r],
      [-r, -r, minX, minX],
    ]
    const yMin = [
      [minY, -r, -r, minY],
      [minY, minY, -r, minY],
      [minY, minY, minY, minY],
      [-r, -r, -r, minY],
    ]

    const x1 = xMin[a1Quad][a0Quad]
    const y1 = yMin[a1Quad][a0Quad]
    const x2 = xMax[a1Quad][a0Quad]
    const y2 = yMax[a1Quad][a0Quad]

    _minX = Math.min(_minX, tx + x1)
    _minY = Math.min(_minY, ty + y1)
    _maxX = Math.max(_maxX, tx + x2)
    _maxY = Math.max(_maxY, ty + y2)
  }

  function closePath() {
  }

  return {
    moveTo,
    lineTo,
    arc,
    closePath,
    minX: () => _minX,
    minY: () => _minY,
    maxX: () => _maxX,
    maxY: () => _maxY,
  }
}

function calculateOuterArcs({
  budget,
  innerRadius,
  outerRadius,
  outerSegmentIndex,
  t = 1,
}) {
  if (outerSegmentIndex === -1) {
    // If there is no selected segment, the outer circle is always going to be a complete circle.
    let parentStartAngle = 0
    let parentEndAngle = Math.PI * 2
    const pie = d3
      .pie()
      .value(d => d.total / budget.total)
      .startAngle(parentStartAngle)
      .endAngle(parentEndAngle)
      .padAngle(0.005)

    const segments = pie(budget.children)
    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(3)
    return {
      segments,
      arcGenerator,
      bounds: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      transform: "",
      outerStartAngle: parentStartAngle,
      outerEndAngle: parentEndAngle,
    }
  } else {
    const { segments } = calculateOuterArcs({
      budget,
      innerRadius,
      outerRadius,
      outerSegmentIndex: -1,
    })
    const activeSegment = segments[outerSegmentIndex]
    // Calculate angle scale as a fraction of the old angle to the desired angle.
    let oldAngle = activeSegment.endAngle - activeSegment.startAngle
    let desiredAngle = toRadians(gSettings.desiredAngleDegrees)
    let angleScale = desiredAngle / oldAngle

    // Based on the angle scale, calculate the total angle of all segments.
    // This can never be larger than a full circle (PI * 2).
    let totalAngle = desiredAngle * angleScale
    totalAngle = clamp(totalAngle, 0, Math.PI * 2)

    // Calculate the position of the middle of our segment on a full circle (0.0 - 1.0).
    let midPos =
      lerp(activeSegment.startAngle, activeSegment.endAngle, 0.5) /
      (Math.PI * 2)
    // This is the final rotation of the circle (PI / 2 ==> 90 degrees)
    let newMiddleAngle = Math.PI / 2
    // Calculate how far off we are from the middle of our segment to the desired position.
    let angleDelta = midPos * totalAngle
    let outerStartAngle = newMiddleAngle - angleDelta
    let outerEndAngle = newMiddleAngle + totalAngle - angleDelta
    // If the turn is too big, rotate in the other direction.
    if (outerStartAngle < -Math.PI) {
      outerStartAngle += Math.PI * 2
      outerEndAngle += Math.PI * 2
    }
    // Calculate the new angles of the arc when zoomed.
    const pie = d3
      .pie()
      .value(d => d.total / budget.total)
      .startAngle(outerStartAngle)
      .endAngle(outerEndAngle)
      .padAngle(0.002)
    const newSegments = pie(budget.children)
    console.assert(segments.length === newSegments.length)
    const interpolatedSegments = []
    for (let i = 0; i < segments.length; i++) {
      const startAngle = lerpCubic(
        segments[i].startAngle,
        newSegments[i].startAngle,
        t,
        0.5,
      )
      const endAngle = lerpCubic(
        segments[i].endAngle,
        newSegments[i].endAngle,
        t,
        0.5,
      )
      const padAngle = lerpCubic(
        segments[i].padAngle,
        newSegments[i].padAngle,
        t,
        0.5,
      )
      interpolatedSegments.push({
        startAngle,
        endAngle,
        padAngle,
        index: i,
        data: segments[i].data,
      })
    }

    let ctx = measureContext()
    // let path = new Bezier()

    const arcMeasurer = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .context(ctx)
    arcMeasurer(newSegments[outerSegmentIndex])
    // console.log(path)
    // const bounds = { x: 0, y: 0, width: 10, height: 10 }
    const bounds = {
      x: ctx.minX(),
      y: ctx.minY(),
      width: ctx.maxX() - ctx.minX(),
      height: ctx.maxY() - ctx.minY(),
    }

    let dstX = -(window.innerWidth / 2) + 100
    let dstY = 0
    dstX = lerpCubic(0.0, dstX, t, 1.0, 0.0)
    dstY = lerpCubic(0.0, dstY, t, 1.0, 0.0)
    let dstWidth = 200
    let dstHeight = 300
    const sx = dstWidth / bounds.width
    const sy = dstHeight / bounds.height
    let scale = Math.min(sx, sy)
    scale = lerpCubic(1.0, scale, t, 1.0, 0.1)
    let tx = -bounds.width / 2 - bounds.x
    let ty = -bounds.height / 2 - bounds.y
    tx = lerpCubic(0.0, tx, t, 1.0, 0.0)
    ty = lerpCubic(0.0, ty, t, 1.0, 0.0)
    let transform = `translate(${dstX}, ${dstY}) scale(${scale}, ${scale}) translate(${tx}, ${ty})`

    const newInnerRadius = lerpCubic(
      innerRadius,
      lerp(innerRadius, outerRadius, 0.5),
      1 - t, // I don't understand why I have to flip this animation.
      0.2,
      0.8,
    )

    const cornerRadius = lerpCubic(3, 1, 1 - t, 0.2, 0.8)

    const arcGenerator = d3
      .arc()
      .innerRadius(newInnerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(cornerRadius)

    arcGenerator.context(null)
    return {
      segments: interpolatedSegments,
      arcGenerator,
      bounds,
      transform,
      outerStartAngle,
      outerEndAngle,
    }
  }
}

function ArcViz({ budget, trail, hoverItemIndex, onClickItem, container }) {

  const [t, setT] = useState(1)
  const [currentTrail, setCurrentTrail] = useState([])
  const [width, setWidth] = useState(null)
  // useEffect(x => {

  //   console.log("trail changed", x)
  //   // while (t < 1.0) {
  //   //   // window.requestAnimationFrame(() => setT(t + 0.02))
  //   // }
  // }, trail)

  const svgRef = useRef()
  const debug = false
  const [debugX, setDebugX] = useState(0)
  const [debugY, setDebugY] = useState(0)
  const [debugScale, setDebugScale] = useState(1)

  useEffect(() => {
    if (JSON.stringify(trail) === JSON.stringify(currentTrail)) return
    let startTime
    let frame
    const duration = 2000
    let forward = true

    if (trail.length > currentTrail.length) {
      setCurrentTrail(trail)
    } else {
      forward = false
    }

    const onFrame = timestamp => {
      if (!startTime) startTime = timestamp
      const elapsed = timestamp - startTime
      const t = elapsed / duration
      if (elapsed < duration) {
        setT(forward ? t : 1 - t)
        frame = window.requestAnimationFrame(onFrame)
      } else {
        setCurrentTrail(trail)
      }
    }
    window.requestAnimationFrame(onFrame)
    return () => window.cancelAnimationFrame(frame)
  }, [trail])

  const debugMouseDown = e => {
    e.preventDefault()
    window.addEventListener("mousemove", debugMouseMove)
    window.addEventListener("mouseup", debugMouseUp)
  }

  const debugMouseMove = e => {
    const bounds = svgRef.current.getBoundingClientRect()
    e.preventDefault()
    setDebugX(debugX + e.offsetX - bounds.width / 2)
    setDebugY(debugY + e.offsetY - bounds.height / 2)
  }

  const debugMouseUp = e => {
    e.preventDefault()
    window.removeEventListener("mousemove", debugMouseMove)
    window.removeEventListener("mouseup", debugMouseUp)
  }

  const debugMouseWheel = e => {
    e.preventDefault()
    const scaleDelta = 1 - e.deltaY * 0.005
    setDebugScale(debugScale * scaleDelta)
  }

  useEffect(() => {
    setWidth(container.current.offsetWidth)
  }, [container.current])

  if (!width) {
    return <div></div>
  }

  const height = 520

  const colors = d3.scaleOrdinal(d3.schemeDark2)

  let parentItem = budget
  let rings = []
  let innerRadius = 100
  let outerRadius = 120
  let ringsTransform

  let outerSegmentIndex = currentTrail.length ? currentTrail[0] : -1
  const {
    segments,
    arcGenerator,
    bounds,
    transform,
    outerStartAngle,
    outerEndAngle,
  } = calculateOuterArcs({
    budget,
    innerRadius,
    outerRadius,
    outerSegmentIndex,
    t,
  })
  ringsTransform = transform

  const arcs = segments.map((segment, i) => (
    <path
      key={i}
      className="pointer-events-auto"
      d={arcGenerator(segment)}
      fill={colors(i)}
      onClick={() => onClickItem(i)}
    />
  ))
  rings.push(<g>{arcs}</g>)

  innerRadius += 11
  outerRadius -= 1

  let parentStartAngle
  let parentEndAngle
  if (trail.length > 0 && t >= 0.6) {
    const activeSegment = segments[trail[0]]
    parentStartAngle = activeSegment.startAngle + 0.005
    parentEndAngle = activeSegment.endAngle - 0.005

    for (const index of trail) {
      parentItem = budget.children[index]
      const colors = d3.scaleOrdinal(d3.schemeAccent)

      const pie = d3
        .pie()
        .value(d => d.total / parentItem.total)
        .startAngle(parentStartAngle)
        .endAngle(parentEndAngle)
        .padAngle(0.002)
      // const outerAngles = pie(parentItem.children)
      // console.log(outerAngles)
      const pieInstance = pie(parentItem.children)
      const arcGenerator = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .cornerRadius(1)
      const arcs = pieInstance.map((segment, i) => (
        <path
          key={i}
          d={arcGenerator(segment)}
          fill={colors(i)}
        />
      ))
      rings.push(<g className="fade-in">{arcs}</g>)
      // parentItem = parentItem.children[index]
      // console.log(pieInstance[index])
      // parentStartAngle = pieInstance[index].startAngle
      // parentEndAngle = pieInstance[index].endAngle
      innerRadius += 5
      outerRadius -= 5
    }
  }

  if (debug) {
    rings.push(
      <rect
        x={bounds.x}
        y={bounds.y}
        width={bounds.width}
        height={bounds.height}
        stroke="red"
        fill="none"
        opacity={t}
      />,
    )
  }

  return (
    <div>
      <svg
        width={width}
        height={height}
        onMouseDown={debug ? debugMouseDown : null}
        onWheel={debug ? debugMouseWheel : null}
        ref={svgRef}
      >
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          <g
            transform={`scale(${debugScale}, ${debugScale}) translate(${debugX}, ${debugY})`}
          >
            <g transform={ringsTransform}>{rings}</g>
          </g>
        </g>
        {debug && (
          <g>
            <line
              x1={0}
              y1={height / 2 - 0.5}
              x2={width}
              y2={height / 2 - 0.5}
              stroke="red"
            />
            <line
              x1={width / 2 - 0.5}
              y1={0}
              x2={width / 2 - 0.5}
              y2={height}
              stroke="red"
            />
          </g>
        )}
      </svg>
    </div>
  )
}

export default ArcViz
