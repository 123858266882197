import * as React from "react"
import {Button} from "antd"
import {Link} from "gatsby-plugin-intl"
import QuizStyle from "../less/quiz-CTA.module.less"
import {useIntl} from "react-intl";

const QuizCTA = () => {
  const intl = useIntl();

  return (
    <div className={QuizStyle.block}>
      <div className={QuizStyle.sideImage}/>
      <div className={QuizStyle.content}>
        <h2>{intl.formatMessage({id: 'quiz_CTA_title'})}</h2>
        <p>{intl.formatMessage({id: 'quiz_CTA_explanation'})}</p>
        <Link to="/quiz">
          <Button color="#00CDB4"
                  type="primary">{intl.formatMessage({id: 'quiz_CTA_buttontext'})}</Button>
        </Link>
      </div>
      <div className={QuizStyle.sideImage}/>
    </div>
  )
}

export default QuizCTA
