import Container from "./container";
import ThemeCard from "./themeCard";
import React from "react";

const HomeDetail = ({budget, trail}) => {
  let activeItem = budget

  for (const index of trail) {
    activeItem = activeItem.children[index]
  }

  if (!activeItem.children) {
    return <div/>
  }

  return (
    <Container size={"small"}>
      <div style={{
        position: "relative",
        zIndex: 10,
        "display": "flex",
        flexDirection: "column",
        marginLeft: "150px",
      }}>
        {activeItem.children.map((item, index) => (
          <ThemeCard key={index}
                     title={item.name}/>
        ))}
      </div>
    </Container>
  )
}

export default HomeDetail

