import React, {useRef, useState} from "react"
import {navigate, Router} from "@reach/router"
import {Link} from "gatsby-plugin-intl"
import posed from "react-pose"
import budgetCategories from "../content/categories.json"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeStyle from "../less/home.module.less"
import SectionStyle from "../less/section.module.less"
import Container from "../components/container"
import {Button, Dropdown, Menu} from "antd"
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined"
import HeroImage from "../images/hero-bg.jpg"
import ArcViz from "../components/arc-viz"
import QuizCTA from "../components/quiz-CTA"
import NewsCard from "../components/news-card"
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined"
import useIntl from "../helpers/useIntl"
import Jazz from "../images/jazz.png"
import News from "../images/news.png"
import {graphql} from "gatsby"
import ProjectCard from "../components/project-card"
import CardGrid from "../components/card-grid"
import {DownOutlined} from "@ant-design/icons"
import HomeOverview from "../components/home-overview"
import HomeDetail from "../components/home-detail"

const IndexPage = (props) => {
  const intl = useIntl()

  const newsItems = props.data.allNewsItem.edges
  const projects = props.data.allProject.edges

  const vizContainer = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState(0)

  let trail = props["*"] ? props["*"].split("/").map(
    (index) => parseInt(index)) : []

  const onClick = (index) => {
    navigate(`./home/${index}`)
  }

  const goBack = () => {
    navigate(-1)
  }

  const yearDropdownMenu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer"
           href="#">
          2018
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer"
           href="#">
          2019
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer"
           href="#">
          2020
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout heroImage={HeroImage}
            heroTextColor={"white"}
            heroAltText={"Brussels skyline"}
            heroTitle={intl.formatMessage({id: "home_hero_title"})}
            heroSubtitle={intl.formatMessage({id: "home_hero_sub_title"})}
            heroSize={"large"}>
      <SEO title="Home"/>
      <section
        className={SectionStyle.isGrayHighlighted + " " + SectionStyle.noPadding}>
        <div className={HomeStyle.container}>
          <div className={HomeStyle.header}>
            {
              props["*"] && // only show backbutton when graph details are visible
              <>
                <div className={HomeStyle.left}>
                  <Button onClick={goBack}
                          type='link'
                          icon={
                            <LeftOutlined/>}>{intl.formatMessage({id: 'home_back'})}</Button>
                </div>
                <span className={HomeStyle.center}/>
                <div className={HomeStyle.right}>
                  <Button icon={<SearchOutlined/>}/>
                  <Dropdown overlay={yearDropdownMenu}>
                    <a className="ant-dropdown-link is-clear"
                       onClick={e => e.preventDefault()}>
                      <span>2020</span>
                      <DownOutlined/>
                    </a>
                  </Dropdown>
                </div>
              </>
            }

            {
              !props["*"] &&
              <>
                <div className={HomeStyle.left}>
                </div>
                <span className={HomeStyle.center}>{intl.formatMessage(
                  {id: "home_clickoncircle"})}</span>
                <div className={HomeStyle.right}>
                  <Button icon={<SearchOutlined/>}/>
                  <Dropdown overlay={yearDropdownMenu}>
                    <a className="ant-dropdown-link is-clear"
                       onClick={e => e.preventDefault()}>
                      <span>2020</span>
                      <DownOutlined/>
                    </a>
                  </Dropdown>
                </div>
              </>
            }
          </div>
          <Router basepath="/:lang/home">
            <HomeOverview path="/"
                          setSelectedCategory={setSelectedCategory}
                          budget={budgetCategories}
                          selectedCategory={selectedCategory}/>

            <HomeDetail path="/*"
                        budget={budgetCategories[selectedCategory]}
                        trail={trail}/>
          </Router>
        </div>

        <div ref={vizContainer}
             className={HomeStyle.vizContainer}>
          {!process.env.GATSBY_DISABLE_GRAPH && <ArcViz
            container={vizContainer}
            budget={budgetCategories[0]}
            onClickItem={index => onClick(index)}
            trail={trail}
            hoverItemIndex={null}
          />}
        </div>
      </section>

      <section>
        <Container size={"small"}>
          <h3>{intl.formatMessage({id: 'home_news_title'})}</h3>
          {newsItems.map((newsItem) => (
            <div style={{marginBottom: "20px"}}>
              <NewsCard
                key={newsItem.id}
                title={intl.translate(newsItem.node, "title")}
                link={newsItem.node.slug}
                coverImageAlt={''}
                coverImageLink={News}
                description={"Om hun verschillende diensten te kunnen uitoefenen, beheren gemeenten een aanzienlijk patrimonium in de vorm van gebouwen en terreinen: zoals gemeentehuizen, sporthallen, bibliotheken, culturele centra en zwembaden. Daarnaast beheren zij ook straatmeubilair en de openbare verlichting, en moeten zij voertuigen, computers en kantoormeubilair voorzien voor hun personeel. Het onderhouden, vernieuwen of bouwen van die eigendommen vereist belangrijke investeringen…"}/>
            </div>
          ))}
        </Container>
      </section>

      <section
        className={SectionStyle.isHighlighted + " " + SectionStyle.noPadding}>
        <Container size={"small"}>
          <div style={{padding: "50px"}}>
            <QuizCTA/>
          </div>
        </Container>
      </section>

      <section>
        <Container size={"small"}>
          <h3>{intl.formatMessage({id: "home_projects_title"})}</h3>
            <CardGrid>
              {projects.map((project, i) => (
                <ProjectCard
                  key={i}
                  title={intl.translate(project.node, "title")}
                  slug={project.node.slug}
                  categories={project.node.fields.projectCategories}
                  description={intl.translate(project.node, "description")}
                  coverImageLink={Jazz}
                  coverImageAlt={''}
                />
              ))}
            </CardGrid>
          <div style={{textAlign: "right", marginTop: '20px'}}>
            <Link to={"/projects"}>
              <Button type={"primary"}>
                {intl.formatMessage({id: "projects_all"})}</Button>
            </Link>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allProject {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
          description_nl
          description_fr
          fields {
            projectCategories {
              name
              title_fr
              title_nl
            }
          }
        }
      }
    }
    allNewsItem {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
        }
      }
    }
  }
`

